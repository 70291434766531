import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  CanActivateChildFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { PermissionFlagsService } from '@emma-services/permission-flags.service';
import { PERMISSION_ID, PERMISSION_TYPE } from 'emma-common-ts/emma';
import { Observable } from 'rxjs';

const isRouteEnabled = (path: string, permissionsService: PermissionFlagsService): boolean => {
  // Known public routes
  if (['/', '/index', '/login', '/logout', '/register', '/forgot-password'].includes(path)) {
    return true;
  }
  const routePaths = path.split('/').filter((r) => r !== 'legacy');
  // Why 2? To skip root empty path.
  for (let i = routePaths.length; i >= 2; --i) {
    const incrementalPath = routePaths.slice(0, i).join('/');
    const permissionId = `${PERMISSION_TYPE.ROUTE}__${incrementalPath}` as PERMISSION_ID;
    if (!permissionsService.canWrite(permissionId)) {
      return false;
    }
  }
  return true;
};

export const routePermissionGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> | Promise<boolean> | boolean => {
  const permissionsService = inject(PermissionFlagsService);
  const router = inject(Router);

  const queryParamsIdx = state.url.indexOf('?');
  const path = queryParamsIdx < 0 ? state.url : state.url.substring(0, queryParamsIdx);
  if (isRouteEnabled(path, permissionsService)) {
    return true;
  }
  router.navigateByUrl('/').catch(console.error);
  return false;
};

export const routePermissionChildGuard: CanActivateChildFn = routePermissionGuard;
